import React from 'react'
import Layout from '../../../components/Layout'
import { Link, graphql } from 'gatsby'
import {Accordion, Card, Button}  from 'react-bootstrap'

import Seo from '../../../components/SEO'

import * as styles  from '../../../styles/projects.module.css'

export default function index({data}) {

  const projects = data.projects.nodes
  
    return (
        <Layout>
          <Seo
            title="Laravel Projects"
            description="List of real-world applications builds on Laravel, a PHP framework."
          />
          <h1>Laravel Projects</h1>
            <Accordion defaultActiveKey="0">
                {projects.map(project => (
                        <Card key={project.id}>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="0">
                            { project.frontmatter.title }
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body className={styles.projects}>
                            <div dangerouslySetInnerHTML={{ __html: project.html }} />
                            <Link to={"/projects/laravel/" + project.frontmatter.slug}>
                              <Button>Learn</Button>
                            </Link>
                            </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                ))}
            </Accordion>         
        </Layout>
    )
}

export const query = graphql`
query LaravelProjects {
  projects: allMarkdownRemark(
    filter: {fileAbsolutePath: {glob: "**/projects/laravel/*"}}
    sort: {order: ASC, fields: frontmatter___title}
    ) {
    nodes {
        frontmatter {
        slug
        title
        }
        id
        html
    }
    }
  }
`
